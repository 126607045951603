import { useState, useEffect } from "react";
import Map, { Marker } from "react-map-gl";

import icon1 from "../images/marker/marker-1.png";
import icon2 from "../images/marker/marker-2.png";
import icon3 from "../images/marker/marker-3.png";
import icon4 from "../images/marker/marker-4.png";
import icon5 from "../images/marker/marker-5.png";

import iconGold from "../images/marker/marker-gold.png";
import iconCar from "../images/marker/marker-car.png";
import iconDeal from "../images/marker/marker-deal.png";
import iconJewels from "../images/marker/marker-jewels.png";
import iconSeller from "../images/marker/marker-seller.png";
import iconWeapon from "../images/marker/marker-weapon.png";
import iconWood from "../images/marker/marker-wood.png";
import iconBridge from "../images/marker/bridge.png";
import iconWoodCoal from "../images/marker/marker-holzkohle.png";
import iconAramid from "../images/marker/marker-aramid.png";
import iconMohn from "../images/marker/marker-mohn.png";
import iconWoddenPart from "../images/marker/marker-WoddenParts.png";
import iconGeldCleanup from "../images/marker/marker-geldwasche.png";
import iconSaw from "../images/marker/marker-saw.png";
import iconHero from "../images/marker/marker-hero.png";
import iconNuss from "../images/marker/marker-nusse.png";
import iconDrugs from "../images/marker/marker-lsd.png";
import iconBlatt from "../images/marker/marker-blatt.png";
import iconChemical from "../images/marker/marker-chemic.png";
import iconAnvil from "../images/marker/marker-anvil.png";
import iconHome from "../images/marker/marker-home.png";
import iconPlate from "../images/marker/marker-plate.png";
import iconWeste from "../images/marker/marker-weste.png";

import "mapbox-gl/dist/mapbox-gl.css";

import Accordion from "../props/Accordion";

function MapPage() {
  const [dbHeroin, setDbHeroin] = useState([]);
  const [dbKoka, setDbKoka] = useState([]);
  const [dbLSD, setDbLSD] = useState([]);

  useEffect(() => {
    async function fetchDataHeroin() {
      const response = await fetch("http://api.cosk.io:5065/api/hero");
      const data = await response.json();
      setDbHeroin(data);
    }
    async function fetchDataKoka() {
      const response = await fetch("http://api.cosk.io:5065/api/okak");
      const data = await response.json();
      setDbKoka(data);
    }
    async function fetchDataLSD() {
      const response = await fetch("http://api.cosk.io:5065/api/dsl");
      const data = await response.json();
      setDbLSD(data);
    }
    fetchDataHeroin();
    fetchDataKoka();
    fetchDataLSD();
  }, []);

  const [selectedOption, setSelectedOption] = useState("None");

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  const [viewport, setViewport] = useState({
    latitude: 40.748,
    longitude: -74.014,
    zoom: 12,
    minZoom: 10.3,
    maxZoom: 15,
    maxBounds: [
      [-74.28347829606261, 40.501550086127565], // southwest corner
      [-73.70223019895292, 41.04339092678427], // northeast corner
    ],
  });

  const [isShownMarker, setIsShownMarker] = useState(false);
  const handleToggleClickMarker = () => {
    setIsShownMarker(!isShownMarker);
  };

  const [isShownDefault, setIsShownDefault] = useState(true);

  useEffect(() => {
    if (selectedOption !== "None") {
      setIsShownDefault(false);
    } else {
      setIsShownDefault(true);
    }
  }, [selectedOption]);

  const produktion = {
    "LSD-Produktion": [
      {
        step: 1,
        name: "Chemikalien",
        description: "Hier werden Chemikalien gesammelt.",
        lon: -74.02585219079293,
        lat: 40.67577474928555,
        icon: icon1,
        img: "https://i.imgur.com/bjjw8df.png",
      },
      {
        step: 2,
        name: "Chemikalien zu LSA",
        description: "Hier werden Chemikalien zu LSA verarbeitet.",
        additional: "Verarbeitung: 1zu1",
        lon: -73.92777620568923,
        lat: 40.89367917503,
        icon: icon2,
        img: "https://i.imgur.com/uDceprt.png",
      },
      {
        step: 3,
        name: "LSA zu LSD",
        description: "Hier wird LSA zu fertigem LSD verarbeitet.",
        additional: "Verarbeitung: 1zu1",
        lon: -74.05001463893151,
        lat: 40.87160119327649,
        icon: icon3,
        img: "https://i.imgur.com/HdFGF27.png",
      },
      {
        step: 4,
        name: "LSD Verkäufer",
        description: "Hier wird fertiges LSD verkauft.",
        additional: "Bestpreis: $250",
        lon: -74.0730549768895,
        lat: 40.72012970008913,
        icon: icon4,
        img: "https://i.imgur.com/5IbuPPP.png",
      },
      {
        step: 5,
        name: "Schwarzgeldwäscher",
        description: "Hier wird Schwarzgeld zu normalem Geld gewaschen.",
        additional: "Kosten: -25%",
        time: "20min",
        lon: -73.9978141502824,
        lat: 40.90276009987858,
        icon: icon5,
        img: "https://i.imgur.com/tdXl3hd.png",
      },
    ],
    "KOKA-Produktion": [
      {
        step: 1,
        name: "KOKA-Feld",
        description: "Hier werden Kokainblätter gesammelt.",
        lon: -73.8356313118769,
        lat: 40.60779899910338,
        icon: icon1,
        img: "https://i.imgur.com/0lej5yQ.png",
      },
      {
        step: 2,
        name: "Kokainblätter zu Koks",
        description: "Hier werden Kokainblätter zu fertigem Koks verarbeitet.",
        additional: "Verarbeitung: 1zu1",
        lon: -73.99603381010779,
        lat: 40.85874626927608,
        icon: icon2,
        img: "https://i.imgur.com/WSFHDUf.png",
      },
      {
        step: 3,
        name: "Koks Verkäufer",
        description: "Hier wird fertiges Koks verkauft.",
        additional: "Bestpreis: $250",
        lon: -74.0730549768895,
        lat: 40.72012970008913,
        icon: icon3,
        img: "https://i.imgur.com/5IbuPPP.png",
      },
      {
        step: 4,
        name: "Schwarzgeldwäscher",
        description: "Hier wird Schwarzgeld zu normalem Geld gewaschen.",
        additional: "Kosten: -25%",
        time: "20min",
        lon: -73.9978141502824,
        lat: 40.90276009987858,
        icon: icon4,
        img: "https://i.imgur.com/tdXl3hd.png",
      },
    ],
    "HEROIN-Produktion": [
      {
        step: 1,
        name: "Mohnfeld",
        description: "Hier wird Mohn gesammelt.",
        lon: -74.0521301566925,
        lat: 40.95836298865552,
        icon: icon1,
        img: "https://i.imgur.com/fMOV5Uy.png",
      },
      {
        step: 2,
        name: "Mohn zu Heroin",
        description: "Hier wird Mohn zu Heroin verarbeitet.",
        lon: -74.05527921887825,
        lat: 40.942414812084905,
        icon: icon2,
        img: "https://i.imgur.com/RjrGnQG.png",
      },
      {
        step: 3,
        name: "Mohn Verkäufer",
        description: "Hier wird fertiges Mohn verkauft.",
        additional: "Bestpreis: $250",
        lon: -74.0730549768895,
        lat: 40.72012970008913,
        icon: icon3,
        img: "https://i.imgur.com/5IbuPPP.png",
      },
      {
        step: 4,
        name: "Schwarzgeldwäscher",
        description: "Hier wird Schwarzgeld zu normalem Geld gewaschen.",
        additional: "Kosten: -25%",
        time: "20min",
        lon: -73.9978141502824,
        lat: 40.90276009987858,
        icon: icon4,
        img: "https://i.imgur.com/tdXl3hd.png",
      },
    ],
    "Westen-Produktion": [
      {
        step: 1,
        name: "Aramidfeld",
        description: "Hier wird Aramid gesammelt.",
        lon: -74.01755649112161,
        lat: 40.88615397147211,
        icon: icon1,
        img: "https://i.imgur.com/Ufrt9bI.png",
      },
      {
        step: 2,
        name: "Aramid zu Kevlar",
        description: "Hier wird Aramid zu Kevlar verarbeitet.",
        additional: "Verarbeitung: 6 Aramid = 1 Kevlar",
        lon: -73.9873816176674,
        lat: 40.89711054552052,
        icon: icon2,
        img: "https://i.imgur.com/0M4qfrA.png",
      },
      {
        step: 3,
        name: "ballistische Platten",
        description: "Hier werden ballistische Platten gesammelt.",
        lon: -73.97350065971699,
        lat: 40.85203893355728,
        icon: icon3,
        img: "https://i.imgur.com/yiTovmj.png",
      },
      {
        step: 4,
        name: "Westenverarbeiter",
        description: "Hier werden Westen hergestellt.",
        additional: "Verlust: ca. -25%",
        lon: -74.03797696561598,
        lat: 40.7344579574243,
        icon: icon4,
        img: "https://i.imgur.com/E62iuPp.png",
      },
    ],
    "Langwaffen-Produktion": [
      {
        step: 1,
        name: "Mine",
        description: "Hier wird Eisen gesammelt.",
        lon: 23.624394569716923,
        lat: -53.52539062500001,
      },
      {
        step: 2,
        name: "Schmelze",
        description: "Hier werden Eisennuggets zu Eisenbarren verarbeitet.",
        lon: -61.19621314083867,
        lat: -10.87646484375,
      },
      {
        step: 3,
        name: "Blechverarbeiter",
        description: "Hier werden Eisenbaaren zu Blech verarbeitet.",
        lon: -60.59775635343141,
        lat: -14.062500000000002,
      },
      {
        step: 4,
        name: "Baumarkt",
        description: "Hier wird das Bleck verkauft.",
        lon: 49.781264058178344,
        lat: 30.410156250000004,
      },
    ],
  };

  const [openAccordion, setOpenAccordion] = useState("");

  const [marker, setMarker] = useState({
    latitude: 40.748,
    longitude: -74.014,
  });

  const onMarkerDragEnd = (event) => {
    setMarker({
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng,
    });
  };

  return (
    <div className="flex">
      <div className="h-full w-fit">
        <Map
          initialViewState={{
            latitude: 40.748,
            longitude: -74.014,
            zoom: 12,
            minZoom: 10.3,
            maxZoom: 15,
            maxBounds: [
              [-74.28347829606261, 40.501550086127565], // southwest corner
              [-73.70223019895292, 41.04339092678427], // northeast corner
            ],
          }}
          {...viewport}
          onMove={(evt) => setViewport(evt.viewState)}
          style={{ width: "100vh", height: "100vh" }}
          mapStyle="mapbox://styles/0nlyghost/clg52j7te003k01qpd3b6dj3b"
          mapboxAccessToken="pk.eyJ1IjoiMG5seWdob3N0IiwiYSI6ImNsZzR6eWNoNjAxd2Eza2syM2ZyaWJqYTcifQ.apWSHAKp7z8VIiS2kjbS4w"
        >
          {selectedOption !== "None" ? (
            <div>
              {produktion[selectedOption].map((item, index) => (
                <Marker
                  latitude={item.lat}
                  longitude={item.lon}
                  color="red"
                  className="text-center"
                  key={index}
                >
                  <img
                    src={item.icon}
                    width={25}
                    height={25}
                    className="mx-auto"
                    alt="Icon3"
                  />
                  <p>{item.name}</p>
                </Marker>
              ))}
            </div>
          ) : (
            <></>
          )}
          {isShownMarker ? (
            <Marker
              latitude={marker.latitude}
              longitude={marker.longitude}
              draggable
              color="blue"
              onDrag={onMarkerDragEnd}
            />
          ) : (
            <></>
          )}

          {isShownDefault ? (
            <>
              <Marker
                latitude={40.676443446560086}
                longitude={-74.02809204300979}
                color="red"
                className="text-center"
              >
                <img
                  src={iconCar}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Autodiebstahl</p>
              </Marker>
              <Marker
                latitude={40.657256101388924}
                longitude={-73.97503012357434}
                color="red"
                className="text-center"
              >
                <img
                  src={iconBridge}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Brüke nach Cayo</p>
              </Marker>
              <Marker
                latitude={40.7043636654665}
                longitude={-74.02483496428012}
                color="red"
                className="text-center"
              >
                <img
                  src={iconWeapon}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Langwaffenherstellung</p>
              </Marker>
              <Marker
                latitude={40.717535143809386}
                longitude={-74.01005416969706}
                color="red"
                className="text-center"
              >
                <img
                  src={iconDeal}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Schwarzmarkt</p>
              </Marker>
              <Marker
                latitude={40.720006284176804}
                longitude={-74.07308246518232}
                color="red"
                className="text-center"
              >
                <img
                  src={iconSeller}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Drogenhändler</p>
              </Marker>
              <Marker
                latitude={40.748878130671216}
                longitude={-74.05169602562509}
                color="red"
                className="text-center"
              >
                <img
                  src={iconJewels}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Juwelenverkäufer (Untergrund)</p>
              </Marker>
              <Marker
                latitude={40.84636552847229}
                longitude={-74.02370463366692}
                color="red"
                className="text-center"
              >
                <img
                  src={iconGold}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Goldverkäufer</p>
              </Marker>
              <Marker
                latitude={40.883531199191225}
                longitude={-74.02806368272775}
                color="red"
                className="text-center"
              >
                <img
                  src={iconWoodCoal}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Holzkohleverarbeiter</p>
              </Marker>
              <Marker
                latitude={40.8861092889683}
                longitude={-74.01836766579083}
                color="red"
                className="text-center"
              >
                <img
                  src={iconAramid}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Aramid</p>
              </Marker>
              <Marker
                latitude={40.94188155513868}
                longitude={-74.05546465962658}
                color="red"
                className="text-center"
              >
                <img
                  src={iconHero}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Heroinverarbeiter</p>
              </Marker>
              <Marker
                latitude={40.9610725524868}
                longitude={-74.0501343111281}
                color="red"
                className="text-center"
              >
                <img
                  src={iconMohn}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Mohn</p>
              </Marker>
              <Marker
                latitude={40.94461594281947}
                longitude={-74.00109903587484}
                color="red"
                className="text-center"
              >
                <img
                  src={iconWoddenPart}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Holzteile</p>
              </Marker>
              <Marker
                latitude={40.911681994442006}
                longitude={-73.9773281608543}
                color="red"
                className="text-center"
              >
                <img
                  src={iconSaw}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>gemahlene Holzkohle</p>
              </Marker>
              <Marker
                latitude={40.90666287803117}
                longitude={-73.97006744421185}
                color="red"
                className="text-center"
              >
                <img
                  src={iconJewels}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Diamantenverkäufer</p>
              </Marker>
              <Marker
                latitude={40.90477955788387}
                longitude={-73.98907801952231}
                color="red"
                className="text-center"
              >
                <img
                  src={iconChemical}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Thionylchlorid</p>
              </Marker>
              <Marker
                latitude={40.896460507424365}
                longitude={-73.98756086430933}
                color="red"
                className="text-center"
              >
                <img
                  src={iconAnvil}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Aramid Verarbeitung</p>
              </Marker>
              <Marker
                latitude={40.90271798702281}
                longitude={-73.99777813822081}
                color="red"
                className="text-center"
              >
                <img
                  src={iconGeldCleanup}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Geldwäscher</p>
              </Marker>
              <Marker
                latitude={40.893594560979466}
                longitude={-73.92787266260312}
                color="red"
                className="text-center"
              >
                <img
                  src={iconNuss}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>LSA</p>
              </Marker>
              <Marker
                latitude={40.87159791805351}
                longitude={-74.05002346578434}
                color="red"
                className="text-center"
              >
                <img
                  src={iconDrugs}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>LSD Verarbeiter</p>
              </Marker>
              <Marker
                latitude={40.89673652479473}
                longitude={-74.0952182574147}
                color="red"
                className="text-center"
              >
                <img
                  src={iconWood}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Holz</p>
              </Marker>
              <Marker
                latitude={40.607454902087966}
                longitude={-73.83567198286555}
                color="red"
                className="text-center"
              >
                <img
                  src={iconBlatt}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Kokainfeld</p>
              </Marker>
              <Marker
                latitude={40.79383037956771}
                longitude={-74.05588144651026}
                color="red"
                className="text-center"
              >
                <img
                  src={iconHome}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Anwesen</p>
              </Marker>
              <Marker
                latitude={40.8525815728743}
                longitude={-73.97205341578828}
                color="red"
                className="text-center"
              >
                <img
                  src={iconPlate}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>ballistische Platten</p>
              </Marker>
              <Marker
                latitude={40.734560492901124}
                longitude={-74.03791241014348}
                color="red"
                className="text-center"
              >
                <img
                  src={iconWeste}
                  width={25}
                  height={25}
                  className="mx-auto"
                  alt="Icon3"
                />
                <p>Westenverarbeiter</p>
              </Marker>
            </>
          ) : (
            <></>
          )}
        </Map>
      </div>
      <div className="h-full w-1/3 ml-5">
        <h1 className="text-4xl font-bold">Medellin Network</h1>
        <div className="flex items-center" onClick={handleToggleClickMarker}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>
          <h2>lat: {marker.latitude}</h2>
        </div>
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>
          <h2>lon: {marker.longitude}</h2>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Produktionskette auswählen
          </label>
          <select
            id="options"
            name="options"
            value={selectedOption}
            onChange={handleSelectChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="None">-- Bitte wähle eine Option --</option>
            <option value="LSD-Produktion">LSD Produktionskette</option>
            <option value="KOKA-Produktion">KOKA Produktionskette</option>
            <option value="HEROIN-Produktion">Heroin Produktionskette</option>
            <option value="Westen-Produktion">Westen Produktionskette</option>
          </select>
        </div>
        <div>
          {selectedOption !== "None" ? (
            <div>
              {produktion[selectedOption].map((item, index) => (
                <Accordion
                  key={index}
                  title={item.name}
                  open={openAccordion}
                  setOpen={setOpenAccordion}
                >
                  <p>{item.description}</p>
                  <div className="mb-3">
                    <div className="flex item-center mb-1 mt-1">
                      {item.additional && item.additional.length > 0 && (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                          </svg>
                          <>{item.additional}</>
                        </>
                      )}
                    </div>
                    <div className="flex item-center mb-1 mt-1">
                      {item.time && item.time.length > 0 && (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <>Dauer: {item.time}</>
                        </>
                      )}
                    </div>
                  </div>
                  <img
                    src={item.img}
                    width={1920}
                    height={1080}
                    alt="screenshot"
                  />
                </Accordion>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <h1 className="text-2xl font-bold mt-5">Dealerpreise</h1>
          <table className="table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">#</th>
                <th className="px-4 py-2">Bestpreis</th>
                <th className="px-4 py-2">Heute</th>
                <th className="px-4 py-2">Gestern</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">Kokain</td>
                <td className="border px-4 py-2">$280</td>
                <td className="border px-4 py-2">${dbKoka}</td>
                <td className="border px-4 py-2">$154</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">LSD</td>
                <td className="border px-4 py-2">$250</td>
                <td className="border px-4 py-2">${dbLSD}</td>
                <td className="border px-4 py-2">$190</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Heroin</td>
                <td className="border px-4 py-2">$320</td>
                <td className="border px-4 py-2">${dbHeroin}</td>
                <td className="border px-4 py-2">$134</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MapPage;
