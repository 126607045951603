import React, { useState } from "react";

function Accordion({ title, children, open, setOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleAccordion() {
    if (open === title) {
      setOpen("");
    } else {
      setOpen(title);
    }
    setIsOpen(!isOpen);
  }

  return (
    <div className="border-b border-gray-300">
      <button
        className="accordion-button w-full text-left py-2 px-3 text-lg font-medium text-gray-700 focus:outline-none border-solid border rounded-lg border-gray-300 flex items-center"
        onClick={toggleAccordion}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          width={24}
          height={24}
          stroke="currentColor"
          className={`ml-auto transform transition-transform duration-500 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`${
          open === title ? "max-h-96" : "max-h-0"
        } overflow-hidden transition-all duration-500`}
      >
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
