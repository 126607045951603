import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Startseite from "./pages/Startseite.js";
import MapPage from "./pages/MapPage.js";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Routes className="flex-grow">
        <Route exact path="/" element={<Startseite />} />
        <Route exact path="/map" element={<MapPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
